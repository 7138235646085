import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import useIndexStore from './store'

async function setup() {
  await useIndexStore.getState().initializeState()
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(<App />)
}

setup()
