import React, { memo } from 'react'
import { Button, Form, Input, message } from 'antd'
import { TabletOutlined, LockOutlined } from '@ant-design/icons'
import CSS from './css.module.scss'
import { useRequest } from '@/hooks/useRequest'
import useIndexStore from '@/store'
import { useNavigate } from 'react-router-dom'
import { PagePath } from '@/constant/pagePath'

const LoginContent = memo(() => {
  const nav = useNavigate()
  const setToken = useIndexStore(state => state.setToken)
  const { fetchLogin } = useIndexStore()
  const { loading, run } = useRequest(fetchLogin)

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values)
    run(
      {
        mobile: values.phone,
        password: values.password,
      },
      {
        onSuccess(res: any) {
          if (res.code !== 200) {
            message.error(res.msg)
            return
          }
          setToken(res.data.token)
          nav(PagePath.MAIN, { replace: true })
        },
        onError(err) {
          message.error(err.message)
        },
      }
    )
  }

  return (
    <div className={CSS['register-content']}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: '请输入正确的手机号',
              pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, 'g'),
            },
          ]}
        >
          <Input prefix={<TabletOutlined />} placeholder="手机号" />
        </Form.Item>

        <Form.Item
          name="password"
          getValueFromEvent={event => {
            const value = event.target.value
            const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/
            if (regex.test(value) || value === '') {
              return value
            }
            return value.replace(/[\u4e00-\u9fa5、！￥……——、’；【】，。]/g, '')
          }}
          rules={[
            {
              required: true,
              message: '请输入6-18位密码',
              min: 6,
              max: 18,
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="密码" />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
})

export default LoginContent
