import BJRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
import useIndexStore from '@/store'

const bjRequest = new BJRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  hooks: {
    requestHook(config) {
      // console.log('config-----------: ', config)
      const token = useIndexStore.getState().token
      if (token && config.headers) {
        config.headers.token = token
      }
      return config
    },
  },
})

export { bjRequest }
