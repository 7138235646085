import zh_CN from 'antd/es/locale/zh_CN'
import en_US from 'antd/es/locale/en_US'

import { useTranslation } from 'react-i18next'
import i18n from '@/react-i18next'
import cache from '@/utils/cache'
import { LNG } from '@/constant/cache'

const antdLocales = {
  zh_CN,
  en_US,
}

let antdLocale = antdLocales[cache.getLocalSorage(LNG) as keyof typeof antdLocales] || zh_CN

/**
 * 网页国际化hook
 */
export function useInternationalization() {
  const { t } = useTranslation()

  function changeLanguage(lng: string) {
    const antdLng = antdLocales[lng as keyof typeof antdLocales]
    if (antdLng) {
      antdLocale = antdLng
    }
    i18n.changeLanguage(lng)
    cache.setLocalSorage(LNG, lng)
  }

  return {
    t,
    i18n,
    antdLocale,
    changeLanguage,
  }
}
