import { create } from 'zustand'

const store = (set: any) => ({
  menus: [],
  async fetchMenuList() {
    return new Promise(resolve => {
      const newMenu = [
        {
          path: '/main',
          title: '主页',
        },
      ]
      set(() => ({
        menus: newMenu,
      }))

      resolve(true)
    })
  },
})

const useMenuStore = create(store)

export default useMenuStore
