import { useState } from 'react'

interface IOptsions {
  onSuccess?: (res: any) => void
  onError?: (err: any) => void
}

export function useRequest(requestFn: (...arg: any) => Promise<any>) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(false)

  const run = async (params: any, optsions?: IOptsions) => {
    try {
      setLoading(true)
      const res = await requestFn(params)
      optsions?.onSuccess?.(res)
      setData(res)
      setLoading(false)
    } catch (error) {
      optsions?.onError?.(error)
      setLoading(false)
    }
  }

  return {
    run,
    loading,
    data,
  }
}
