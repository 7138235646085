import React, { memo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { PagePath } from '@/constant/pagePath'
import useIndexStore from '@/store'

interface IProps {
  children: JSX.Element
}

const AuthRouter = memo(({ children }: IProps) => {
  const { pathname } = useLocation()
  const token = useIndexStore(state => state.token)

  if (token && pathname === PagePath.LOGIN) {
    return <Navigate to={PagePath.MAIN} replace={true} />
  }

  if (!token && pathname !== PagePath.LOGIN) {
    return <Navigate to={PagePath.LOGIN} replace={true} />
  }

  return children
})

export default AuthRouter
