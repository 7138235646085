/**
 * 开发环境：development
 * 生产环境：production
 * 测试环境：test
 */
let BASE_URL = ''
const TIME_OUT = 30000

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/proxy' // 代理
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'http://studio.gzshibahao.com'
}

export { BASE_URL, TIME_OUT }
