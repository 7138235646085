import React, { memo } from 'react'
import { Button, Col, Form, Input, Row, message } from 'antd'
import {
  TabletOutlined,
  LockOutlined,
  StarOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons'
import CSS from './css.module.scss'
import { useCaptcha } from '@/hooks/useCaptcha'
import { useRequest } from '@/hooks/useRequest'
import * as loginAPI from '@/api/login'
import { useNavigate } from 'react-router-dom'
import { PagePath } from '@/constant/pagePath'
import useIndexStore from '@/store'

const RegisterContent = memo(() => {
  const nav = useNavigate()
  const setToken = useIndexStore(state => state.setToken)
  const fetchRegister = useIndexStore(state => state.fetchRegister)
  const [form] = Form.useForm()
  const { code, getCode, isSetup } = useCaptcha()
  const captchaReq = useRequest(loginAPI.getRegisterCode)
  const submitReq = useRequest(fetchRegister)

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values)
    submitReq.run(
      {
        code: values.code,
        mobile: values.phone,
        password: values.password,
        captcha: values.captcha,
      },
      {
        async onSuccess(res) {
          console.log('res: ', res)
          if (res.code !== 200) {
            message.error(res.msg)
            return
          }
          setToken(res.data.token)
          nav(PagePath.MAIN, { replace: true })
        },
        onError(err) {
          message.error(err.message)
        },
      }
    )
  }

  async function sendCode() {
    try {
      const { phone } = await form.validateFields(['phone'])
      captchaReq.run(
        { mobile: phone },
        {
          onSuccess(res) {
            console.log('res: ', res)
            if (res.code !== 200) {
              message.error(res.msg)
              return
            }
            message.success('发送成功')
            getCode()
          },
          onError(err) {
            message.error(err.message)
          },
        }
      )
    } catch (error) {
      console.log('error: ', error)
    }
  }

  return (
    <div className={CSS['register-content']}>
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: '请输入正确的手机号',
              pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, 'g'),
            },
          ]}
        >
          <Input prefix={<TabletOutlined className="site-form-item-icon" />} placeholder="手机号" />
        </Form.Item>

        <Form.Item>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                name="captcha"
                noStyle
                rules={[{ required: true, message: '请输入正确的验证码' }]}
              >
                <Input prefix={<SafetyCertificateOutlined />} placeholder="验证码" />
              </Form.Item>
            </Col>
            <Col span={8}>
              {isSetup ? (
                <Button block disabled>
                  {code}s
                </Button>
              ) : (
                <Button block onClick={sendCode} loading={captchaReq.loading}>
                  获取验证码
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="password"
          getValueFromEvent={event => {
            const value = event.target.value
            const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/
            if (regex.test(value) || value === '') {
              return value
            }
            return value.replace(/[\u4e00-\u9fa5、！￥……——、’；【】，。]/g, '')
          }}
          rules={[
            {
              required: true,
              message: '请输入6-18位密码',
              min: 6,
              max: 18,
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="密码" />
        </Form.Item>

        <Form.Item name="code" rules={[{ required: true, message: '请输入正确的邀请码' }]}>
          <Input prefix={<StarOutlined />} placeholder="邀请码" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit" className="login-form-button">
            注册
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
})

export default RegisterContent
