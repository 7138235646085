import React, { memo } from 'react'
import { Card, Tabs } from 'antd'
import CSS from './css.module.scss'
import RegisterContent from './cpns/RegisterContent'
import loginContent from './cpns/LoginContent'

const items = [
  {
    text: '登录',
    Component: loginContent,
  },
  {
    text: '注册',
    Component: RegisterContent,
  },
]

const Login = memo(() => {
  return (
    <div className={CSS.login}>
      <div className={CSS['card_box']}>
        <div className={CSS['title_box']}>
          <div className={CSS['title']}>AIQ STUDIO</div>
          <div>SD，打造你的AI绘画助手</div>
        </div>
        <Card style={{ width: 400, padding: 0 }} bodyStyle={{ padding: 0 }}>
          <Tabs
            tabBarGutter={50}
            centered
            defaultActiveKey="1"
            items={items.map((item, i) => {
              return {
                label: <span>{item.text}</span>,
                key: item.text,
                children: <item.Component />,
              }
            })}
          />
        </Card>
      </div>
    </div>
  )
})

export default Login
