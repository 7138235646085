import { Navigate } from 'react-router-dom'
import Login from '@/pages/Login'

export const defaultRouter = [
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      title: '登录',
    },
  },
]
