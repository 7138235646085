import { ConfigProvider } from 'antd'
import { RouterComponent } from './router'
import { HashRouter } from 'react-router-dom'
import AuthRouter from './router/AuthRouter'
import Demo1 from './components/demo/Demo1'
import { useInternationalization } from './hooks/useInternationalization'

function App() {
  const { antdLocale } = useInternationalization()
  return (
    <ConfigProvider locale={antdLocale}>
      {/* <Demo1 /> */}
      <HashRouter>
        <AuthRouter>
          <RouterComponent />
        </AuthRouter>
      </HashRouter>
    </ConfigProvider>
  )
}

export default App
