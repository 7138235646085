import { useState } from 'react'

/**
 * 验证码hook
 */
export function useCaptcha(initCode = 59) {
  const [code, setCode] = useState(initCode)
  const [isSetup, setIsSetup] = useState(false)

  function getCode() {
    setIsSetup(true)
    const timeId = setInterval(() => {
      setCode(state => {
        if (state === 1) {
          clearInterval(timeId)
          setIsSetup(false)
          return initCode
        }
        return (state -= 1)
      })
    }, 1000)
  }

  return {
    code,
    isSetup,
    getCode,
  }
}
