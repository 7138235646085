import React, { memo, useEffect } from 'react'
import CSS from './css.module.scss'
import useIndexStore from '@/store'
import { notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons'

const openNotification = () => {
  notification.open({
    message: 'hi~ 欢迎回来',
    description: '',
    icon: <SmileOutlined style={{ color: '#1AAD19' }} />,
  })
}

const Main = memo(() => {
  const token = useIndexStore(state => state.token)
  const modelUrl = `http://183.6.57.154:36080/?__theme=dark&token=${token}`
  useEffect(() => {
    openNotification()
  }, [])
  return (
    <div className={CSS.main}>
      <iframe
        id={CSS['my-iframe']}
        src={modelUrl}
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </div>
  )
})

export default Main
