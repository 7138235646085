import { bjRequest } from '../'

/**
 * 注册接口
 */
export function register(data = {}) {
  return bjRequest.post({
    url: '/api/user/register',
    data,
  })
}

/**
 * 登录接口
 */
export function login(data = {}) {
  return bjRequest.post({
    url: '/api/user/login',
    data,
  })
}

/**
 * 获取注册验证码
 */
export function getRegisterCode(data = {}) {
  return bjRequest.post({
    url: '/api/user/send',
    data,
  })
}

/**
 * 校验token
 */
export function verifyToken() {
  return bjRequest.get({
    url: '/api/user/checkToken',
  })
}
