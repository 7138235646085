import Main from '@/pages/Main'

export const mainRouter = {
  path: '/main',
  element: <Main />,
  meta: {
    title: '主页',
  },
}

export default mainRouter
